import revive_payload_client_vH6zsyIcVj from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20_uslaq7o5muskadmdts7zkwbimm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9GMbFJzquA from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20_uslaq7o5muskadmdts7zkwbimm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5MePVzHNBh from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20_uslaq7o5muskadmdts7zkwbimm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_xN1bvT0pFK from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.3.5/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_OaRETlx5q7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20_uslaq7o5muskadmdts7zkwbimm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_NtjEvoqutb from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20_uslaq7o5muskadmdts7zkwbimm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_NWlW6V3sFc from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20_uslaq7o5muskadmdts7zkwbimm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_WcepvOgyYJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20_uslaq7o5muskadmdts7zkwbimm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_59O68PzUXW from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.20.0_typescript@5.5.4_vue@3.4.37_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_tXlMXuGJdw from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20_uslaq7o5muskadmdts7zkwbimm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_GDf0qABhF7 from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.30.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._74pdd5r6zesc3byyq7ca5uzudm/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
export default [
  revive_payload_client_vH6zsyIcVj,
  unhead_9GMbFJzquA,
  router_5MePVzHNBh,
  supabase_client_xN1bvT0pFK,
  payload_client_OaRETlx5q7,
  navigation_repaint_client_NtjEvoqutb,
  check_outdated_build_client_NWlW6V3sFc,
  chunk_reload_client_WcepvOgyYJ,
  plugin_vue3_59O68PzUXW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_tXlMXuGJdw,
  sentry_client_GDf0qABhF7,
  sentry_client_config_o34nk2sJbg
]